<template>
  <div ref="zoneItem"
       :class="zoneItemClass"
       class="zone-item"
       @mouseenter="toggleHover(true)"
       @mouseleave="toggleHover(false)">
    <transition name="fade">
      <Drawer v-if="openColorPicker || openEditForm"
              v-on-clickaway="clickAwayFromZoneItem"
              :posY="`${drawerTriangleTopPosition}%`"
              :style="drawerStyle"
              side="right"
              class="drawer">
        <ColorPicker v-if="openColorPicker"
                     class="color-picker"
                     width="150px"
                     @hover="showHoveredColor"
                     @click="setClickedColor" />
        <ParcelForm v-if="openEditForm"
                    :parcel="{ name: zone.name, type: zone.type}"
                    class="zone-item__form"
                    @submit="updateZone" />
      </Drawer>
    </transition>
    <div class="zone-info o-button o-button--shadows"
         :class="zoneInfoClass"
         @click.stop="emitClick"
         @mouseenter="$emit('hoverin')"
         @mouseleave="$emit('hoverout')">
      <span :class="zoneNameClass"
            class="zone-info__name">
        {{ zone.name.slice(0, 22) }}
      </span>
      <span v-if="subLabel"
            class="zone-info__sublabel">
        {{ subLabel }}
      </span>
      <span :style="zoneInfoColorStyle"
            :class="zoneInfoColorClass"
            class="zone-info__color"
            @click="toggleColorPicker">&nbsp;</span>
    </div>
    <div v-if="hover"
         class="zone-action">
      <span class="zone-action__action"
            @click="toggleEditForm">
        {{$t('pages.buildingConfiguration.parcel.parcelEdition.buttons.edit')}}
      </span>
      <span class="zone-action__separator" />
      <span class="zone-action__action"
            @click="$emit('delete', zone.uuid)">
        {{$t('pages.buildingConfiguration.parcel.parcelEdition.buttons.delete')}}
      </span>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import ColorsMixin from '@/app/mixins/colors';

import ParcelForm from '@/app/pages/building-configuration/components/parcel-form.vue';
import Drawer from '@/app/components/ui/drawer.vue';
import ColorPicker from '@/app/components/inputs/color-picker.vue';

export default {
  name: 'ZoneItem',
  components: {
    Drawer,
    ColorPicker,
    ParcelForm,
  },
  directives: { onClickaway },
  mixins: [ColorsMixin],
  props: {
    zone: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
    chipMode: {
      type: Boolean,
      default: false,
    },
    buttonMode: {
      type: Boolean,
      default: false,
    },
    subLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hover: false,
      openColorPicker: false,
      openEditForm: false,
      hoveredColor: null,
      drawerTriangleTopPosition: 0,
      currentOffsetTop: 0,
    };
  },
  computed: {
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    drawerStyle() {
      const topPosition = this.calculateDrawerTopPosition();
      return {
        top: `${topPosition}px`,
      };
    },
    zoneItemClass() {
      return {
        'zone-item--hover': this.hover,
        'zone-item--chip': this.chipMode,
        'zone-item--active': this.active,
      };
    },
    zoneInfoClass() {
      return {
        'o-button--hover': this.chipMode,
      };
    },
    zoneInfoColorStyle() {
      return {
        'background-color': this.hoveredColor || this.zone.color || this.colors.silver,
      };
    },
    zoneInfoColorClass() {
      return {
        'zone-info__color--editing': this.openColorPicker,
      };
    },
  },
  mounted() {
    this.$parent.$on('scroll', this.updateOffsetTop);
    this.updateOffsetTop();
  },
  methods: {
    calculateDrawerTopPosition() {
      let topPosition;
      if (this.currentOffsetTop < window.innerHeight / 2) {
        this.drawerTriangleTopPosition = 2;
        topPosition = this.currentOffsetTop - 50; // 50 = header size
      } else {
        const drawerHeight = this.openEditForm ? 175 : 70;
        this.drawerTriangleTopPosition = this.openEditForm ? 85 : 65;
        topPosition = this.currentOffsetTop - drawerHeight - 50;
      }
      return topPosition;
    },
    updateOffsetTop() {
      const { top } = this.$refs.zoneItem.getBoundingClientRect();
      this.currentOffsetTop = top;
    },
    toggleEditForm() {
      if (this.buttonMode || this.chipMode) return;
      this.openColorPicker = false;
      this.openEditForm = !this.openEditForm;
    },
    clickAwayFromZoneItem() {
      this.openColorPicker = false;
      this.openEditForm = false;
    },
    setClickedColor(color) {
      this.zone.color = color;
      this.openColorPicker = false;

      this.$store.commit('zones/selectZone', null);
      if (this.zone.uuid.length > 6)
        this.$store.dispatch('zones/update', {
          buildingUuid: this.selectedBuilding.uuid,
          zone: {
            uuid: this.zone.uuid,
            name: this.zone.name,
            type: this.zone.type,
            metadata: {
              color: this.zone.color,
            },
          },
        });
    },
    showHoveredColor(color) {
      this.hoveredColor = color;
    },
    toggleHover(hoverState) {
      if (hoverState) this.$emit('mouseover');
      else this.$emit('mouseout');
      if (this.buttonMode || this.chipMode) return;
      this.hover = hoverState;
    },
    emitClick() {
      this.$emit('click', this.zone);
    },
    toggleColorPicker() {
      if (this.buttonMode || this.chipMode) return;
      this.openEditForm = false;
      this.openColorPicker = !this.openColorPicker;
    },
    updateZone(updatedZone) {
      this.zone.name = updatedZone.name;
      this.zone.type = updatedZone.type;
      this.openEditForm = false;
      if (this.zone.uuid.length > 6)
        this.$store.dispatch('zones/update', {
          buildingUuid: this.selectedBuilding.uuid,
          zone: {
            uuid: this.zone.uuid,
            name: this.zone.name,
            type: this.zone.type,
            metadata: {
              color: this.zone.color,
            },
          },
        });
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.zone-item
  position relative
  display flex
  align-items center
  &--hover
    background-color $light
  .drawer
    position fixed
    right 100%
  .zone-item__form
    margin 16px
  .zone-info
    display flex
    margin $space-little 0
    margin-left $space-2
    padding 0
    width 60%
    height auto
    border-radius $border-radius-rounded
    background-color white
    .zone-info__name
      display flex
      flex 2
      justify-content space-between
      align-items center
      padding $space-little
      padding-left $space-2
      width 100%
      border-top-left-radius $border-radius-rounded
      border-bottom-left-radius $border-radius-rounded
      font-weight $fw-regular
      font-size $fs-body
    .zone-info__sublabel
      display flex
      flex 1
      justify-content center
      align-items center
      padding $space-little $space-1
      background-color $light
      text-align right
      font-weight $fw-light
      font-size $fs-little
    .zone-info__color
      padding $space-little
      width 20%
      height 100%
      height 100%
      border-top-right-radius $border-radius-rounded
      border-bottom-right-radius $border-radius-rounded
      cursor pointer
      &--editing
        border-right 2px solid black
  .zone-action
    display flex
    flex 1
    justify-content center
    align-items center
    user-select none
    span
      margin-right 4px
      &:last-child
        margin-right 0
    .zone-action__separator
      width 1px
      height 12px
      background-color black
    .zone-action__action
      padding 1px
      cursor pointer
      &:hover
        font-weight 700

.zone-item--active
  .zone-info__name
    background-color $black
    color white
  .zone-info__sublabel
    background-color $dark !important
    color white

.zone-item--chip
  width 100%
  .zone-info
    margin 0
    width 100%

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
</style>
