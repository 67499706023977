<template>
  <div class="properties-list">
    <property-item :values="access"
                   name="access" />
    <property-item :values="type"
                   name="type" />
  </div>
</template>


<script>
import PropertyItem from '@/app/pages/building-configuration/components/property-item.vue';

export default {
  name: 'PropertiesList',
  components: { PropertyItem },
  computed: {
    properties() {
      return this.$store.state.zones.properties.collection;
    },
    access() {
      return this.properties.access.map(e => {
        return {
          label: this.$t(`pages.buildingConfiguration.properties.access.${e}`),
          value: e,
        };
      });
    },
    type() {
      return this.properties.type.map(e => {
        return {
          label: this.$t(`pages.buildingConfiguration.properties.type.${e}`),
          value: e,
        };
      });
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.properties-list
  flex-grow 1
  overflow-y auto
  padding-bottom 50px
  height 100%
</style>
