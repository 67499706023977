<template>
  <form class="parcel-form"
        @submit="emitParcelCreation"
        @keyup.enter="emitParcelCreation">
    <div class="o-label">
      {{$t('pages.buildingConfiguration.parcel.parcelEdition.form.name')}}
    </div>
    <input ref="parcelNameInput"
           v-model="newParcel.name"
           type="text"
           class="form__input o-input o-input--inversed">
    <div class="o-label">
      {{$t('pages.buildingConfiguration.parcel.parcelEdition.form.type')}}
    </div>
    <SelectField v-model="newParcel.type"
                 :options="parcelType"
                 class="select"
                 color="white" />
    <div class="form__submit o-button o-button--shadows o-button--hover create-category-button"
         @click="emitParcelCreation">
      {{ buttonText }}
    </div>
  </form>
</template>

<script>
import SelectField from '@/app/components/inputs/select-field.vue';

export default {
  name: 'ParcelForm',
  components: { SelectField },
  props: {
    parcel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      newParcel: {
        name: '',
        type: 'apartment',
      },
      parcelType: [
        { label: this.$t('pages.buildingConfiguration.parcel.parcelEdition.form.apartment'), value: 'apartment' },
        { label: this.$t('pages.buildingConfiguration.parcel.parcelEdition.form.office'), value: 'office' },
      ],
    };
  },
  computed: {
    buttonText() {
      return this.parcel
        ? this.$t('pages.buildingConfiguration.parcel.parcelEdition.form.update')
        : this.$t('pages.buildingConfiguration.parcel.parcelEdition.form.create');
    },
  },
  mounted() {
    if (this.parcel) this.newParcel = this.parcel;
    this.$refs.parcelNameInput.focus();
  },
  methods: {
    emitParcelCreation() {
      this.$emit('submit', this.newParcel);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.parcel-form
  .form__input
    margin-bottom 15px
  .select
    margin-bottom 16px
  .form__submit
    margin 0 auto
    width 50%
    background-color white
    color black
    text-align center
</style>
