<template>
  <div class="properties-sidebar">
    <space-info />
    <div class="properties-container">
      <span class="properties-title">
        {{$t('pages.buildingConfiguration.properties.title')}}
      </span>
      <properties-list />
    </div>
  </div>
</template>

<script>
import SpaceInfo from '@/app/components/spaces/space-info.vue';
import PropertiesList from '@/app/pages/building-configuration/components/properties-list.vue';
import ColorsMixin from '@/app/mixins/colors';

export default {
  name: 'PropertiesSidebar',
  components: { SpaceInfo, PropertiesList },
  mixins: [ColorsMixin],
  computed: {
    currentWhitelistedSpaces() {
      return this.$store.getters['building/currentWhitelistedSpaces'];
    },
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    currentProperty() {
      return this.$store.state.zones.properties.selectedProperty;
    },
    propertiesColors() {
      return this.$store.state.zones.properties.colors;
    },
    typeSpaceList() {
      return this.$store.getters['zones/properties/spaceListByCurrentPropertyType'];
    },
  },
  watch: {
    typeSpaceList() {
      if (!this.currentProperty) return;
      this.resetSpacesDefaultColor();
      this.colorSpaces();
    },
  },
  mounted() {
    this.$store.dispatch('zones/properties/fetch');
  },
  methods: {
    resetSpacesDefaultColor() {
      const allSpaces = this.$palmier.spaces({ whitelist: this.currentWhitelistedSpaces });
      allSpaces.apply({
        style: s => (s.fill = this.colors.silver),
      });
    },
    colorSpaces() {
      const type = Object.keys(this.currentProperty)[0];
      const filteredSpaces = this.typeSpaceList.filter(space => this.currentWhitelistedSpaces.includes(space.uuid));
      filteredSpaces.forEach(space => {
        const spaceElem = this.$palmier.getSpace(space.uuid);
        if (spaceElem) spaceElem.style.fill = this.propertiesColors[type][space.properties[type]] || this.colors.silver;
      });
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.properties-sidebar
  display flex
  flex-direction column
  height 100%
  .properties-container
    display flex
    flex-grow 1
    flex-direction column
    overflow-y auto
    .properties-title
      display flex
      padding 20px 20px 35px 20px
      width 100%
      border-bottom solid 1px $grey
      text-transform uppercase
      font-weight 700
      font-size $fs-h1
    .space-list__drawer
      position absolute
      top 0px
      right 350px
      padding 10px
      max-width 300px
      width fit-content
      transition all 0.3s ease
      .space-list
        display flex
        flex-wrap wrap
        .space-name
          margin-right 5px
          font-size 1rem
    .properties__no-select
      margin-top 20px
      text-align center
      font-weight 700
      font-size $fs-h2

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
</style>
