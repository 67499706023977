<template>
  <div class="conf-step">
    <div class="conf-step__header">
      <div class="current-step">
        {{ currentStep }}/{{ totalSteps }}
      </div>
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="conf-step__main">
      <div class="description">
        {{ description }}
      </div>
      <div class="advice">
        {{ advice }}
      </div>
    </div>
    <div class="conf-step__footer">
      <q-btn :class="progress >= 50 ? 'button--active': ''"
             class="button"
             @click="emitClick">
        {{ buttonText }}
      </q-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfigurationSteps',
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    totalSteps: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: '',
    },
    progress: {
      type: Number,
      default: 20,
    },
    title: {
      type: String,
      default: '',
    },
    advice: {
      type: String,
      default: '',
    },
  },
  computed: {
    buttonText() {
      if (this.currentStep === this.totalSteps) return 'Finish';
      return 'next step';
    },
  },
  methods: {
    emitClick() {
      if (this.progress < 50) {
        this.$q
          .dialog({
            title: `Only ${this.progress}% of the spaces is configured, are you sure to skip this step ?`,
            ok: true,
            cancel: true,
            color: 'black',
          })
          .then(() => {
            this.$emit('next');
          })
          .catch(() => {});
      } else this.$emit('next');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.conf-step
  display flex
  flex-direction column
  padding 24px
  width 100%
  height 300px
  background black
  color white
  .conf-step__header
    display flex
    flex 8
    .current-step
      font-weight 100
      font-size 6rem
      line-height 0.95em
    .title
      margin-top 25px
      margin-left 50px
      font-size $fs-h1
  .conf-step__main
    flex 7
    .description
      font-size $fs-h1
  .conf-step__footer
    flex 2
    .button
      width 100%
      background-color $dark
      color $light
      font-weight 700
      transition all 200ms ease
      &--active
        background-color white
        color black
        transition all 200ms ease
</style>
