<template>
  <q-layout ref="layout"
            class="building-configuration">
    <q-layout-drawer :width="350"
                     :value="true"
                     class="sidebar"
                     side="left">
      <BuildingInfo v-if="!firstConfig"
                    :building="currentBuilding"
                    @delete="val => deletingBuilding = val"
                    @menuSelection="setCurrentMenu" />
      <div class="building-preferences">
        <div class="button-block">
          <q-btn icon="fas fa-undo-alt"
            round
            size="sm"
            :loading="rotateLoading"
            @click="updatePreferences({ rotate: -10 })" />
          <span class="info">
            {{ this.$store.state.building.selectedBuilding.metadata.preferences.rotation }}°
          </span>
          <q-btn icon="fas fa-redo-alt"
            round
            size="sm"
            :loading="rotateLoading"
            @click="updatePreferences({ rotate: 10 })" />
        </div>
        <div class="button-block">
          <q-btn icon="fas fa-search-minus"
            round
            size="sm"
            :disable="zoomOutDisabled"
            :loading="zoomLoading"
            @click="updatePreferences({ zoomIncrease: -0.1 })" />
          <span class="info">
            {{ this.$store.state.building.selectedBuilding.metadata.preferences.zoom }}
          </span>
          <q-btn icon="fas fa-search-plus"
            round
            size="sm"
            :loading="zoomLoading"
            @click="updatePreferences({ zoomIncrease: 0.1 })" />
        </div>
      </div>

      <steps v-if="firstConfig"
             :totalSteps="steps.total"
             :currentStep="steps.current"
             :title="stepsMessages[steps.current - 1].title"
             :description="stepsMessages[steps.current - 1].description"
             :advice="stepsMessages[steps.current - 1].advice"
             @next="nextStep" />
      <!-- :progress="stepsProgress" -->
    </q-layout-drawer>
    <q-page-container class="building-configuration__building">
      <NavInfo>
        <template slot="navigation">
          <NavItem @click="goBack">
            <ChevronPicto size="10px"
                          class="back-button-arrow" />
          </NavItem>
          <NavItem disabled
                   class="building-name">
            {{currentBuilding.name}}
          </NavItem>
        </template>
      </NavInfo>
      <div class="viewer">
        <building-viewer v-if="currentBuilding && !deletingBuilding"
                         :editorMode="false"
                         class="building-viewer" />
        <div v-if="deletingBuilding"
             class="loading-container">
          <span class="loading__message">
            building is being removed...
          </span>
          <spinner :size="80"
                   class="loading__spinner" />
        </div>
      </div>
    </q-page-container>
    <q-layout-drawer :width="350"
                     :value="true"
                     class="sidebar"
                     side="right">
      <component :is="menuComponent" />
    </q-layout-drawer>
  </q-layout>
</template>

<script>
import { mapMutations } from 'vuex';

import Building from 'models/topology/building';

import BuildingInfo from '@/app/pages/building-configuration/components/building-info.vue';
import NavInfo from '@/app/components/layout/nav-info';
import NavItem from '@/app/components/layout/nav-info/components/navigation-item.vue';
import ChevronPicto from '@/app/components/ui/pictos/chevron.vue';
import BuildingViewer from '@/app/views/building-viewer';
import ParcelEdition from '@/app/pages/building-configuration/views/parcel-edition.vue';
import PropertiesSidebar from '@/app/pages/building-configuration/views/properties-sidebar.vue';
import Spinner from '@/app/components/ui/spinner.vue';
import Steps from '@/app/pages/building-configuration/components/steps.vue';

export default {
  name: 'BuildingConfiguration',
  components: {
    BuildingInfo,
    NavInfo,
    NavItem,
    ChevronPicto,
    BuildingViewer,
    Spinner,
    Steps,
  },
  props: {
    firstConfig: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zoomLoading: false,
      rotateLoading: false,
      deletingBuilding: false,
      currentMenu: null,
      menu: {
        parcelEdition: {
          component: ParcelEdition,
          viewerMode: 'zoneEdition',
        },
        propertiesEdition: {
          component: PropertiesSidebar,
          viewerMode: 'propertiesEdition',
        },
      },
      steps: {
        current: 1,
        total: 2,
      },
      stepsMessages: [
        {
          menu: 'parcelEdition',
          title: 'Zones Edition',
          description: 'Create zones and group spaces',
          advice: `Cover at least 50% of the building's spaces`,
          nbFunction: 'getCurrentBuildingZonesSpaceCount',
        },
        {
          menu: 'propertiesEdition',
          title: 'Properties Edition',
          description: 'Edit spaces properties',
          advice: `Cover at least 50% of the building's spaces`,
          nbFunction: 'getNbSpacesFilter',
        },
      ],
    };
  },
  computed: {
    getCurrentBuildingZonesSpaceCount() {
      return this.$store.getters['zones/getCurrentBuildingZonesSpaceCount'];
    },
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    zones() {
      return this.$store.state.zones.collection;
    },
    zoomOutDisabled() {
      return this.$store.state.building.selectedBuilding.metadata.preferences.zoom <= 0.1;
    },
    menuComponent() {
      if (!this.currentMenu || !this.menu[this.currentMenu]) return null;
      return this.menu[this.currentMenu].component;
    },
    stepsProgress() {
      //Get spaces total and current spaces selected
      if (!this.currentBuilding) return;
      const nbConfigured = this[this.stepsMessages[this.steps.current - 1].nbFunction];
      const buildingSpaces = Building.getSpaces(this.currentBuilding.uuid);
      const nbTotal = buildingSpaces.length;
      return ((nbConfigured / nbTotal) * 100).toFixed(1);
    },
  },
  created(){
    if (!this.$store.state.building.selectedBuilding.metadata.preferences.zoom)
      this.$store.state.building.selectedBuilding.metadata.preferences.zoom = 1;
    if (!this.$store.state.building.selectedBuilding.metadata.preferences.rotation)
      this.$store.state.building.selectedBuilding.metadata.preferences.rotation = -45;
  },
  mounted() {
    if (this.firstConfig) this.setCurrentMenu('parcelEdition');
  },
  beforeDestroy() {
    this.setViewerMode('navigation');
  },
  methods: {
    ...mapMutations('ui', { setViewerMode: 'SET_VIEWER_MODE' }),
    setCurrentMenu(menuName) {
      this.setViewerMode(this.menu[menuName].viewerMode);
      this.currentMenu = menuName;
    },
    nextStep() {
      if (this.steps.current === this.steps.total) this.$router.push({ name: this.$store.getters['user/defaultPage'] });
      else {
        this.steps.current++;
        this.setCurrentMenu(this.stepsMessages[this.steps.current - 1].menu);
      }
    },
    goBack() {
      this.$store.commit('building/setSelectedBuilding', null);
      this.$router.push({ name: this.$store.getters['user/defaultPage'] });
    },
    increase(val, incr) {
      return Number((Number(val) + incr).toFixed(2));
    },
    async updatePreferences(params) {
      if (params.zoomIncrease) {
        this.zoomLoading = true;
        const newVal = this.increase(this.$store.state.building.selectedBuilding.metadata.preferences.zoom, params.zoomIncrease);
        if (newVal > 0)
          this.$store.state.building.selectedBuilding.metadata.preferences.zoom = newVal;
        else {
          this.$q.notify({ message: `Zoom has to be more than 0`, type: 'negative', position: 'bottom-left' });
        }
        this.$store.commit('ui/SET_FLOORS_MIN_ZOOM',
          this.$store.state.building.selectedBuilding.metadata.preferences.zoom);
      }
      if (params.rotate) {
        this.rotateLoading = true;
        this.$store.state.building.selectedBuilding.metadata.preferences.rotation =
          this.increase(this.$store.state.building.selectedBuilding.metadata.preferences.rotation, params.rotate)%360;
        this.$store.commit('ui/SET_CURRENT_FLOORS_ORIENTATION_DEGREE',
          this.$store.state.building.selectedBuilding.metadata.preferences.rotation);
      }
      // clone the object to trigger vue reactivity on it
      this.$store.state.building.selectedBuilding.metadata.preferences = {...this.$store.state.building.selectedBuilding.metadata.preferences};
      const paramsUpdate = {
        uuid: this.$store.state.building.selectedBuilding.uuid,
        preferences: this.$store.state.building.selectedBuilding.metadata.preferences,
      };
      await this.$store.dispatch('building/updatePreferences', paramsUpdate);
      this.zoomLoading = false;
      this.rotateLoading = false;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.building-configuration
  min-height auto
  height 100%
  .sidebar >>> aside
    display flex
    flex-direction column
    overflow visible
  .building-configuration__building
    padding-top 0.1px
    width 100%
    height 100%
    .nav-info
      margin $space-2 $space-2 0
    .viewer
      position relative
      height calc(100% - 51px)
      background-color $silver
      .building-list, .building-viewer
        height 100%
      .loading__spinner
        stroke black
        fill black
    .loading-container
      display flex
      flex-direction column
      justify-content center
      align-items center
      height 100%
      .loading__message
        padding 15px
        width 100%
        color black
        text-align center
        font-size $fs-h1

.back-button-arrow
  transform rotate(90deg)
.building-preferences
  display flex
  gap 20px
  flex-direction column
  align-items center
  font-size 16px
  margin 24px
  .button-block
    display flex
    flex-direction row
    .info
      width 50px
      text-align center
      padding-top 3px
</style>
