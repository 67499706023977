<template>
  <div class="building-conf-menu">
    <div class="building-information">
      <h3 class="building-name">
        {{ building.name }}
      </h3>
      <Building3D :buildingShape="building.metadata.concave_hull"
                  :buildingUUID="building.uuid"
                  :preferences="building.metadata.preferences"
                  :vHeight="150"
                  :vWidth="200"
                  class="building3d" />
      <div class="address">
        <span class="address-row adress__street">
          {{ building.address.line1 }}
        </span>
        <div class="address-row">
          <span class="adress__city">
            {{ building.address.city }}
          </span>
          <span class="adress__zip-code">
            {{ building.address.zipCode }}
          </span>
          <span v-if="country"
                class="adress__country">
            {{ country.label }}
          </span>
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="o-button o-button--shadows o-button--hover o-button--bordered"
           @click="emitConfigurationMenuName('propertiesEdition')">
        {{$t('pages.buildingConfiguration.sidebarIn.menu.spaces')}}
      </div>
      <div class="o-button o-button--shadows o-button--hover o-button--bordered"
           @click="emitConfigurationMenuName('parcelEdition')">
        {{$t('pages.buildingConfiguration.sidebarIn.menu.parcels')}}
      </div>
      <div class="o-button o-button--shadows o-button--hover o-button--negative"
           @click="deleteBuilding">
        {{$t('pages.buildingConfiguration.sidebarIn.menu.deleteBuilding')}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Building3D from '@/app/components/buildings/building3d.vue';

export default {
  name: 'BuildingInfo',
  components: {
    Building3D,
  },
  props: {
    building: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    countries() {
      return this.$store.state.countries.collection;
    },
    country() {
      return this.$store.getters['countries/read'](this.building.address.countryAlpha2);
    },
  },
  mounted() {
    if (this.countries.length === 0) this.$store.dispatch('countries/fetch');
  },
  methods: {
    ...mapMutations('ui', { setViewerMode: 'SET_VIEWER_MODE' }),
    deleteBuilding() {
      this.$q
        .dialog({
          title: `You're about to delete ${this.building.name}, are you sure?`,
          ok: true,
          cancel: true,
          color: 'black',
        })
        .then(async () => {
          try {
            this.$emit('delete', true);
            await this.$store.dispatch('building/delete', this.building.uuid);
            this.$q.notify({
              message: `${this.building.name} successfully deleted`,
              type: 'positive',
              position: 'bottom-left',
            });
            this.$store.commit('building/setSelectedBuilding', null);
            this.$router.push({ name: 'data' });
            this.$emit('delete', false);
          } catch (error) {
            this.$emit('delete', false);
            this.$q.notify({
              message: `error when deleting ${this.building.name}`,
              type: 'negative',
              position: 'bottom-left',
            });
          }
        })
        .catch(() => {});
    },
    emitConfigurationMenuName(menuName) {
      this.$emit('menuSelection', menuName);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.building-conf-menu
  .building-information
    padding 16px
    background-color $black
    color white
    .building-name
      margin 0
      margin-bottom 16px
      text-align center
      font-weight 700
      font-size $fs-h2
      line-height 1
    .building3d
      display flex
      justify-content center
      align-items center
      height fit-content
    .address
      display flex
      flex-direction column
      font-size $fs-h3
      .address-row
        margin-bottom 4px
        text-align center
        span
          margin-right 8px
          &:last-child
            margin-right 0
  .menu
    margin 24px
    .o-button
      margin-bottom 24px
      text-align center
      text-transform uppercase
</style>
