<template>
  <div class="parcel-edition">
    <ParcelInfo :parcel="currentZone" />
    <h3 class="parcel-edition__title">
      {{$t('pages.buildingConfiguration.parcel.parcelEdition.title')}}
    </h3>
    <Chip :enabled="displayAllZones"
          :value="showAllZoneText"
          class="parcel-edition__button o-button--shadows"
          @click.native="handleZoneDisplay" />
    <ZoneItem :buttonMode="true"
              :zone="{color: 'black', name: $t('pages.buildingConfiguration.parcel.parcelEdition.buttons.createParcel')}"
              @click="toggleDrawer">
      <PlusPicto slot="icon"
                 size="60%"
                 color="white" />
    </ZoneItem>
    <Drawer v-if="showDrawer"
            v-on-clickaway="toggleDrawer"
            posY="35px"
            class="drawer"
            side="right">
      <ParcelForm @submit="handleCreateZone" />
    </Drawer>
    <div class="separator" />
    <div class="zone-list"
         @scroll="listenScroll">
      <div v-for="(floor, key) in zonesSortedByFloor"
           :key="key"
           class="zone-floor">
        <span class="floor-name">
          {{ key }}
        </span>
        <ZoneItem v-for="zone in floor"
                  :key="zone.uuid"
                  :zone="zone"
                  :active="isCurrentZone(zone)"
                  @click="selectZone(zone)"
                  @hoverin="highlightZone(zone)"
                  @hoverout="restoreCurrentZone(zone)"
                  @delete="handleDeleteZone(zone)" />
      </div>
    </div>
  </div>
</template>

<script>
import chroma from 'chroma-js';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState } from 'vuex';

import Zone from 'models/topology/zone';

import ColorsMixin from '@/app/mixins/colors';
import UtilsMixin from '@/app/mixins/utils';
import SpaceMixin from '@/app/mixins/building-viewer/actions/space';
import ZoneMixin from '@/app/mixins/building-viewer/zone';

import ParcelInfo from '@/app/pages/building-configuration/components/parcel-info.vue';
import ZoneItem from '@/app/pages/building-configuration/components/zone-item.vue';
import ParcelForm from '@/app/pages/building-configuration/components/parcel-form.vue';
import Drawer from '@/app/components/ui/drawer.vue';
import Chip from '@/app/components/ui/chip.vue';
import PlusPicto from '@/app/components/ui/pictos/plus.vue';

export default {
  name: 'ParcelEdition',
  components: {
    Drawer,
    Chip,
    ParcelInfo,
    ZoneItem,
    ParcelForm,
    PlusPicto,
  },
  directives: { onClickaway },
  mixins: [ColorsMixin, UtilsMixin, SpaceMixin, ZoneMixin],
  data() {
    return {
      displayAllZones: true,
      showDrawer: false,
    };
  },
  computed: {
    ...mapState('ui', ['colorPicker']),
    currentWhitelistedSpaces() {
      return this.$store.getters['building/currentWhitelistedSpaces'];
    },
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    zones() {
      return this.$store.state.zones.collection;
    },
    currentZone() {
      return this.$store.state.zones.selectedZone;
    },
    showAllZoneClass() {
      return {
        'show-zone-btn--active': this.displayAllZones,
      };
    },
    showAllZoneText() {
      return this.displayAllZones
        ? this.$t('pages.buildingConfiguration.parcel.parcelEdition.buttons.hideParcels')
        : this.$t('pages.buildingConfiguration.parcel.parcelEdition.buttons.showParcels');
    },
    zonesSortedByFloor() {
      const noFloorKey = 'Not attributed';
      if (!this.zones[this.currentBuilding.uuid]) return;
      const ret = this.zones[this.currentBuilding.uuid].reduce((acc, z) => {
        if (z.spaces.length <= 0 || !z.floor) {
          if (!(noFloorKey in acc)) acc[noFloorKey] = [];
          acc[noFloorKey].push(z);
        } else {
          const zonefloor = this.getFloorFromSpaceId(z.spaces[0]);
          if (!(zonefloor.name in acc)) acc[zonefloor.name] = [];
          acc[zonefloor.name].push(z);
        }
        return acc;
      }, {});
      // sort zone by name considering numeric values
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      for (const floors in ret) ret[floors].sort((a, b) => collator.compare(a.name, b.name));
      return ret;
    },
  },
  watch: {
    displayAllZones() {
      this.colorZoneSpaces();
    },
    'currentZone.spaces'() {
      this.colorZoneSpaces();
    },
    'currentZone.color'() {
      this.colorZoneSpaces();
    },
  },
  created() {
    if (this.zones.length <= 0) this.$store.dispatch('zones/fetch');
    this.colorZoneSpaces();
  },
  beforeDestroy() {
    this.$store.commit('zones/selectZone', null);
  },
  methods: {
    listenScroll(evt) {
      this.$emit('scroll');
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    colorZoneSpaces() {
      const spacesToWhitelist = this.currentWhitelistedSpaces;
      const allSpaces = this.$palmier.spaces({ whitelist: spacesToWhitelist });
      allSpaces.apply({
        style: s => (s.fill = this.colors.silver),
      });
      if (this.displayAllZones) this.showAllZones();
      else this.showCurrentZone();
    },
    handleZoneDisplay() {
      this.displayAllZones = !this.displayAllZones;
    },
    showAllZones() {
      if (!this.zones[this.currentBuilding.uuid]) return;
      this.zones[this.currentBuilding.uuid].forEach(z => {
        const zone = this.$palmier.zone(z.spaces);
        zone.apply({
          style: s => (s.fill = z.color),
        });
      });
    },
    highlightZone(zone) {
      const hoveredZone = this.$palmier.zone(zone.spaces);
      let brighterColor;
      if (!zone.color) brighterColor = this.colors.light;
      else brighterColor = chroma(zone.color).brighten(0.5);
      const color = this.displayAllZones ? brighterColor : zone.color;
      hoveredZone.apply({
        style: s => (s.fill = color),
      });
    },
    restoreCurrentZone(zone) {
      const hoveredZone = this.$palmier.zone(zone.spaces);
      const color = this.displayAllZones || this.isCurrentZone(zone) ? zone.color : this.colors.silver;
      hoveredZone.apply({
        style: s => (s.fill = color),
      });
    },
    isCurrentZone(zone) {
      return this.currentZone && this.currentZone.uuid === zone.uuid;
    },
    showCurrentZone() {
      if (!this.currentZone) return;
      const zone = this.$palmier.zone(this.currentZone.spaces);
      zone.apply({
        style: s => (s.fill = this.currentZone.color),
      });
    },
    selectZone(zone) {
      if (this.currentZone && zone.uuid === this.currentZone.uuid) this.$store.commit('zones/selectZone', null);
      else this.$store.commit('zones/selectZone', zone);
    },
    handleCreateZone(zone) {
      if (zone.name === '') return;
      const newZone = new Zone({
        uuid: this.generateRandomUUID(),
        type: zone.type,
        name: zone.name,
        metadata: {
          color: this.getRandomColor(),
        },
        next: [],
      });
      this.$store.commit('zones/create', { buildingUuid: this.currentBuilding.uuid, zone: newZone });
      this.$store.commit('zones/selectZone', newZone);
      this.showDrawer = false;
    },
    getRandomColor() {
      return this.colorPicker[this.random(0, this.colorPicker.length)];
    },
    async handleDeleteZone(zone) {
      if (zone.uuid.length <= 6) {
        this.$store.commit('zones/delete', { buildingUuid: this.currentBuilding.uuid, zoneUuid: zone.uuid });
        return;
      }
      try {
        await this.$store.dispatch('zones/delete', { buildingUuid: this.currentBuilding.uuid, zoneUuid: zone.uuid });
        this.$q.notify({
          message: `${zone.name} deleted`,
          type: 'positive',
          position: 'bottom-left',
        });
        if (this.currentZone && zone.uuid === this.currentZone.uuid) this.$store.dispatch('zones/selectZone', null);
        this.colorZoneSpaces();
      } catch (error) {
        this.$q.notify({
          message: `${zone.name} error on delete`,
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.parcel-edition
  position relative
  display flex
  flex-direction column
  height 100%
  .parcel-edition__button
    margin-bottom 16px
    margin-left 33px
    width 49%
    font-size $fs-body
    & >>> .chip__value
      justify-content start
      padding-left 7px
  .drawer
    position absolute
    top 150px
    right 100%
    padding 16px
  .parcel-edition__title
    margin 16px 32px 16px
    font-weight 700
    font-size $fs-h1
    line-height 1
  .zone-select
    background-color black
    color white
    .q-list
      background-color green
  .zone-list
    display flex
    flex-direction column
    overflow-y auto
    .select
      margin-bottom 16px
      margin-left 32px
      width 49%
    .zone-floor
      margin-bottom 16px
      &:last-child
        margin-bottom 0
    .floor-name
      margin-bottom 16px
      margin-left 32px
      font-weight 700
      font-size $fs-h3
  .separator
    margin 8px 0
    width 100%
    height 1px
    background-color $silver
  .show-zone-btn--active
    >>> .o-button
      background-color black
      color white
</style>
