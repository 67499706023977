<template>
  <div class="parcel-info">
    <span v-if="!parcel"
          class="advice">
      {{$t('pages.buildingConfiguration.parcel.parcelInfo.createParcel')}}
    </span>
    <div v-if="parcel && !parcelHasSpaces"
         class="advice">
      {{$t('pages.buildingConfiguration.parcel.parcelInfo.addSpaceToParcel')}}
    </div>
    <div v-if="parcel && parcelHasSpaces"
         class="info">
      <div class="info-row">
        <span class="info-row__label">
          {{$t('pages.buildingConfiguration.parcel.parcelInfo.floor')}}
        </span>
        <span class="info-row__value">
          {{ floorName }}
        </span>
      </div>
      <div class="info-row">
        <span class="info-row__label">
          {{$t('pages.buildingConfiguration.parcel.parcelInfo.spaces')}}
        </span>
        <span class="info-row__value">
          {{ spaces.length }}
        </span>
      </div>
      <!-- <div class="info-row">
        <span class="info-row__label">
          surface
        </span>
        <span class="info-row__value">
          {{ parcelSurface }}
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import SpaceMixin from '@/app/mixins/building-viewer/actions/space';

export default {
  name: 'ParcelInfo',
  mixins: [SpaceMixin],
  props: {
    parcel: {
      type: Object,
      default: null,
    },
  },
  computed: {
    parcelFloor() {
      return this.parcelHasSpaces ? this.getFloorFromSpaceId(this.parcel.spaces[0]) : null;
    },
    floorName() {
      return this.parcelFloor ? this.parcelFloor.name : '';
    },
    parcelHasSpaces() {
      return this.parcel ? this.parcel.spaces.length > 0 : false;
    },
    spaces() {
      if (this.parcelHasSpaces) return this.parcel.spaces;
      else return [];
    },
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    // parcelSurface() {
    //   const surface = this.spaces
    //     .reduce((acc, s) => {
    //       return acc + s.metadata.surface;
    //     }, 0)
    //     .toFixed(1);
    //   return `${surface} m²`;
    // },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.parcel-info
  padding 16px
  background-color black
  color white
  .advice
    display flex
    align-items center
    height 64px
    font-weight 700
    font-size $fs-h2
  .info
    display flex
    flex-direction column
    .info-row
      display flex
      margin-bottom 8px
      .info-row__label
        flex-basis 20%
        text-transform uppercase
      .info-row__value
        flex-basis 80%
        margin-left 8px
        font-weight 700
        font-size $fs-h3
      &:last-child
        margin-bottom 0
</style>
