<template>
  <div class="property__item">
    <span class="property__name">
      {{ name }}
    </span>
    <div class="property__values">
      <property-value-item v-for="entry in values"
                           :key="entry.value"
                           :value="entry.label"
                           :active="valueIsSelected(entry.value)"
                           :color="propertiesColors[name][entry.value] || '#F3F1EF'"
                           @hoverin="highlightValue(name, entry.value)"
                           @hoverout="restoreValueColors(name, entry.value)"
                           @click="selectProperty(entry.value)" />
    </div>
  </div>
</template>

<script>
import chroma from 'chroma-js';
import ColorsMixin from '@/app/mixins/colors';
import PropertyValueItem from '@/app/pages/building-configuration/components/property-value-item.vue';

export default {
  name: 'PropertyItem',
  components: { PropertyValueItem },
  mixins: [ColorsMixin],
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      savedColors: {},
    };
  },
  computed: {
    currentProperty() {
      return this.$store.state.zones.properties.selectedProperty;
    },
    propertiesColors() {
      return this.$store.state.zones.properties.colors;
    },
  },
  methods: {
    selectProperty(value) {
      if (this.valueIsSelected(value)) this.$store.commit('zones/properties/selectProperty', null);
      else this.$store.commit('zones/properties/selectProperty', { [this.name]: value });
      this.savedColors = {};
    },
    valueIsSelected(value) {
      return this.currentProperty && this.name in this.currentProperty && this.currentProperty[this.name] === value;
    },
    highlightValue(type, property) {
      const hoveredPropertySpaces = this.$store.getters['zones/properties/spaceListByProperty']({ [type]: property });
      const spaces = this.$palmier.getSpaces(hoveredPropertySpaces);
      const color = this.propertiesColors[type][property] || '#F3F1EF';
      const brighterColor = chroma(color).brighten(0.7);
      Object.keys(spaces).forEach(s => {
        this.savedColors[s] = spaces[s].style.fill;
        spaces[s].style.fill = brighterColor;
      });
    },
    restoreValueColors(type, property) {
      const hoveredPropertySpaces = this.$store.getters['zones/properties/spaceListByProperty']({ [type]: property });
      const spaces = this.$palmier.getSpaces(hoveredPropertySpaces);
      const color = this.propertiesColors[type][property] || '#F3F1EF';
      Object.keys(spaces).forEach(s => {
        spaces[s].style.fill = this.savedColors[s] || color;
      });
      this.savedColors = {};
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.property__item
  padding 20px 0
  border-top solid 1px $light
  .property__name
    margin-bottom 10px
    margin-left 20px
    text-transform uppercase
    font-size $fs-h2
  .property__values
    margin-top 10px
</style>
